import { utc } from 'dayjs';

import { CancellationPolicy, RoomConfiguration } from '@AuroraTypes';
import { ALL_FLEXIBILITY_VALUES, MONTH_FLEXIBILITY_VALUE } from '@Constants/Flexibility';
import { MINIMUM_BOOKABLE_DATE_OFFSET } from '@Constants/MinimumBookableDate';
import { SortOptions } from '@Constants/SortOptions';
import { unique } from '@Core/helpers/array';
import { getQueryParams } from '@Core/helpers/url';
import { ISO_DATE_FORMAT, parseDate, utcDate } from '@Dates/dates';
import { isAnyAirport } from '@DesignSystemComponents/Search/DepartureInput/DepartureSection';
import {
  maxNumberOfPassengersInARoom,
  maxNumberOfRooms,
} from '@DesignSystemComponents/Search/GuestInput/constants';
import { SearchSelectionStore } from '@Stores/SearchSelectionStore';

const VALID_BOARD_BASIS_REGEX = /^[A-Z]{2}$/;
const VALID_AIRPORT_REGEX = /^[A-Z-]+$/;
const NUMERIC_REGEX = /^\d+$/;
const FALSY_STRING_REGEX = /^(undefined|null)?$/;
const PER_PERSON_BUDGET_REGEX = /^\d+-\d*$/;

const isNumeric = (value: string) => NUMERIC_REGEX.test(value);
const isNotFalsyString = (value: string) => !FALSY_STRING_REGEX.test(value);
const matchesRegex = (regex: RegExp) => (value: string) => (regex.test(value) ? value : undefined);
const isIncluded =
  <T>(arr: Array<T>) =>
  (value: T) =>
    arr.includes(value) ? value : undefined;

const asNumberArray = (value: string) => String(value).split(',').filter(isNumeric).map(Number);
const asStringArray = (value: string) => String(value).split(',').filter(isNotFalsyString);

const asNumber = (value: string) => (isNumeric(value) ? Number(value) : undefined);
const asString = (value: string) => (typeof value === 'string' ? value : undefined);
const asDate = (value: string) =>
  parseDate(value, ISO_DATE_FORMAT) ? utcDate(value, ISO_DATE_FORMAT) : undefined;

const isValidNight = (value: number) => (value <= 28 ? value : undefined);
const isValidAirport = (value: string) =>
  VALID_AIRPORT_REGEX.test(value) || isAnyAirport(value) ? value : undefined;

const map =
  <T>(fn: (arg: T) => any) =>
  (value: Array<T>) =>
    value.map(fn);
const filter =
  <T>(fn: (arg: T) => any) =>
  (value: Array<T>) =>
    value.filter(fn);

// Ignore dates before the minimum bookable date, except when using month flexibility for the
// current month
const handlePastDates = (params: Record<string, any>) => {
  const { date, flexibility, ...rest } = params;
  if (!date) {
    return params;
  }

  const minBookableDate = utc().add(MINIMUM_BOOKABLE_DATE_OFFSET, 'day').startOf('day');

  if (date.isBefore(minBookableDate)) {
    const isSameMonth = date.format('YYYY-MM') === minBookableDate.format('YYYY-MM');
    const isMonthFlex = flexibility === MONTH_FLEXIBILITY_VALUE;

    if (!isSameMonth || !isMonthFlex) {
      return rest;
    }
  }

  return params;
};

const parseRooms = (value: string): RoomConfiguration[] | undefined => {
  try {
    const rooms = value.split(',').map((room) => {
      const [adults, ...childAges] = room.split('-').map(Number);

      if (
        !adults ||
        childAges.some(Number.isNaN) ||
        adults + childAges.length > maxNumberOfPassengersInARoom
      ) {
        throw new Error('Invalid "rooms" value');
      }

      return {
        adults,
        childAges,
      };
    });

    if (rooms.length > maxNumberOfRooms) {
      return;
    }

    return rooms;
  } catch {
    return;
  }
};

const CANCELLATION_POLICY_MAP: Record<string, CancellationPolicy> = {
  FC: 'FREE_CANCELLATION',
};
const parseCancellationPolicy = (value: string) =>
  value
    .split(',')
    .map((val) => CANCELLATION_POLICY_MAP[val])
    .filter((val) => !!val);

const removeFalsyValues = (source: any) => {
  const clone = { ...source };

  for (const key in clone) {
    if (
      (!clone[key] && !isNumeric(clone[key])) ||
      (Array.isArray(clone[key]) && !clone[key].length) ||
      (typeof clone[key] === 'object' && !Object.keys(clone[key]).length)
    ) {
      delete clone[key];
    }
  }

  return clone;
};

const transform = <T>(
  transformDefinition: Record<string, Array<(foo: any) => any>>,
  data: Record<string, T>,
  dataPrefix: string,
) => {
  const result = {} as Record<string, T>;

  Object.keys(transformDefinition).forEach((key: string) => {
    let val = data[`${dataPrefix}${key}`];
    if (val) {
      for (const fn of transformDefinition[key]) {
        val = fn(val);
      }
      result[key] = val;
    }
  });

  return result;
};

const PARAMS_DEFINITION = {
  date: [asDate],
  flexibility: [Number, isIncluded(ALL_FLEXIBILITY_VALUES)],
  destinationIds: [asNumberArray, unique, map(String)],
  departureAirports: [asStringArray, filter(isValidAirport)],
  nights: [Number, isValidNight],
  sort: [isIncluded(SortOptions)],
  masterId: [asNumber],
  rooms: [parseRooms],
  pinnedMasterIds: [asNumberArray, unique],
  boardBasis: [asStringArray, filter(matchesRegex(VALID_BOARD_BASIS_REGEX))],
  filters: [],
  cancellationPolicy: [parseCancellationPolicy, unique],
  maxFlightStops: [asNumberArray],
  includedCheckedBags: [asNumberArray],
};

const FILTERS_DEFINITION = {
  regions: [asStringArray],
  resorts: [asStringArray],
  boardBasis: [asStringArray, filter(matchesRegex(VALID_BOARD_BASIS_REGEX))],
  holidayTypes: [asStringArray],
  hotelFacilities: [asStringArray],
  pools: [asStringArray],
  propertyTypes: [asStringArray],
  kids: [asStringArray],
  maxPerPersonPrice: [asNumber],
  minTripAdvisorRating: [asNumber],
  minHolidayCheckRating: [asNumber],
  starRating: [asNumberArray],
  perPersonBudget: [asString, matchesRegex(PER_PERSON_BUDGET_REGEX)],
  cancellationPolicies: [asStringArray],
  maxFlightStops: [asNumberArray],
  includedCheckedBags: [asNumberArray],
};

export function getSearchSelectionFromUrl(search: string): Partial<SearchSelectionStore> {
  let params = getQueryParams(search);

  const filters = transform(FILTERS_DEFINITION, params, 'f.');

  params.pinnedMasterIds = params.mrIds;
  params = transform(PARAMS_DEFINITION, params, '');
  params.filters = removeFalsyValues(filters);

  params = handlePastDates(params);

  return removeFalsyValues(params);
}
